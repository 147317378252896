<template>
  <div class="page">
    <SimpleHeader />

    <form class="form-recover-pwd text-center">
      <div class="card">
        <div class="card-body">
          <h1 class="h3 font-weight-normal">Esqueceu sua senha?</h1>
          <div>
            <b-form-group
              label=" Escolha o tipo de conta"
              v-slot="{ ariaDescribedby }"
            >
              <div class="row">
                <div class="col-6">
                  <b-form-radio
                    v-model="pessoaJuridica"
                    :aria-describedby="ariaDescribedby"
                    name="some-radios"
                    :value="true"
                    >Pessoa Jurídica</b-form-radio
                  >
                </div>
                <div class="col-6">
                  <b-form-radio
                    v-model="pessoaJuridica"
                    :aria-describedby="ariaDescribedby"
                    name="some-radios"
                    :value="false"
                    >Pessoa física</b-form-radio
                  >
                </div>
              </div>
            </b-form-group>
          </div>
          <div v-if="pessoaJuridica">
            <p>
              Informe seu CNPJ para receber as instruções de recuperação de
              senha.
            </p>
          </div>
          <div v-else>
            Informe seu CPF para receber as instruções de recuperação de senha.
          </div>

          <label for="cnpj" class="sr-only">E-mail</label>
          <div v-if="pessoaJuridica">
            <input
              type="tel"
              id="cnpj"
              class="form-control"
              v-model="cnpj"
              v-mask="'##.###.###/####-##'"
              placeholder="00.000.000/0000-00"
              autofocus
              maxlength="100"
            />
          </div>
          <div v-else>
            <input
              type="text"
              class="form-control"
              v-model="cnpj"
              :class="classes"
              v-mask="'###.###.###-##'"
              placeholder="000.000.000-00"
            />
          </div>

          <button
            @click.prevent="onClickEnviar"
            :disabled="loading"
            class="btn btn-success btn-block mt-2"
          >
            <span v-if="!loading">Enviar</span>
            <span v-if="loading">
              <small class="text-purple">Enviando...</small>
            </span>
          </button>
        </div>
      </div>

      <a href="javascript:void(0);" @click="$router.go(-1)" class="mt-5 d-block"
        ><i class="fas fa-chevron-left"></i> Voltar para a tela inicial</a
      >
    </form>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import axios from "axios";
import helper from "../../helpers/helper";
import captureError from "../../helpers/captureError";
import config from "../../config";

import SimpleHeader from "../../components/SimpleHeader";

export default {
  components: {
    SimpleHeader,
  },
  data() {
    return {
      loading: false,
      cnpj: "",
      pessoaJuridica: true,
    };
  },
  methods: {
    async onClickEnviar() {
      if (!this.cnpj) return;

      this.loading = true;

      try {
        var response = await axios.post(
          `${config.API_URLV2}/auth/EsqueciSenhaLojista?cnpj=${this.cnpj}`,
          {}
        );

        var email = helper.emailMask(response.data);

        await Swal.fire({
          icon: "success",
          text: `Enviamos as instruções para criar uma nova senha de acesso para o e-mail ${email}`,
        });

        this.$router.go(-1);
      } catch (e) {
        this.loading = false;

        if (e.response && e.response.data && e.response.data.invalido) {
          Swal.fire({
            icon: "warning",
            title: "Atenção",
            text:
              "O CNPJ informado não foi encontrado em nossos cadastros. Verifique os dados e tente novamente.",
          });
          return;
        }

        captureError(e, this.cnpj);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text:
            "Não foi possível enviar o e-mail. Tente novamente mais tarde ou contate o suporte.",
        });
      }
    },
  },
};
</script>

<style scoped>
.page {
  background: #f2f2f2;
  min-height: 100vh;
}
.form-recover-pwd {
  width: 100%;
  max-width: 460px;
  padding: 16px;
  margin: 0 auto;
  margin-top: 120px;
}
</style>
